import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { position } from 'styled-system'
import App from 'App'
import { space, colors } from 'theme'
import { Box, Flex } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Header from 'components/Header'
import ellipsisTopRight from 'static/svg/shape1.svg'
import ellipsisBottomLeft from 'static/svg/shape2.svg'

const StyledText = styled(Text)`
  opacity: 0.8;
`

const EllipsisShape = styled.img`
  margin-top: auto;
  position: absolute;
  max-width: 100%;
  height: auto;
  z-index: -1;
  ${position};
`

const StyledBox = styled(Box)`
  background: url(${ellipsisBottomLeft}) no-repeat;
  background-position: left bottom;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
`

const Unsubscribed = () => {
  return (
    <React.Fragment>
      <App isHeaderVisible>
        <StyledBox>
          <EllipsisShape src={ellipsisTopRight} top="0" right="0" />
          <Flex minHeight="100vh" mt="xl" px="m" flexDirection="column" alignItems="center">
            <H1 textAlign="center">All set!</H1>
            <Box mt="l" maxWidth="600px" textAlign="center">
              <StyledText fontSize="xxl" color="secondaryText">
                Your email has been removed from future mailings.
              </StyledText>
            </Box>
            <Box mt="m">
              <StyledLink to="/">Learn more about Agency Vista</StyledLink>
            </Box>
          </Flex>
        </StyledBox>
      </App>
    </React.Fragment>
  )
}

export default Unsubscribed
